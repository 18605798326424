@import '../../vars.css';

.container {
    color: var(--color-text);
    margin-top: 79px;
    margin-left: 8%;
    margin-right: 8%;
}

.title {
    font-family: PPFragment;
    color: var(--color-text);
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    /* text-transform: uppercase; */
    padding-bottom: 25px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 14px;
}

.teamMembers {
    width: 35%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%
}
@media screen and (max-width: 830px) {
    .content {
        flex-direction: column;
        align-items: center;
        gap: 34px;
    }
    .teamMembers {
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }

}