* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html {
    scroll-behavior: smooth;
}
@font-face {
    font-family: PPFragment;
    src: local('PPFragment'),
    url('./fonts/PPFragmentSerifRegular.otf') format('opentype')
}
@font-face {
    font-family: PPNeueMachina;
    src: local('PPNeueMachina'),
    url('./fonts/PPNeueMachinaRegular.otf') format('opentype')
}