

.card {
    display: flex;
    position: relative;
    width: 320px;
    height: 500px;
    overflow: hidden;
    border: 4px solid #fff;
    border-radius: 20px;
  }
  
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  .card-body {
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    position: absolute;
    background: #1f3d4722;
    backdrop-filter: blur(5px);
    border-radius: 16px;
    color: white;
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s;
  }
  .card:hover .card-body {
    right: 0;
  }

  .card-title {
    font-family: PPFragment;
    /* text-transform: uppercase; */
    font-size: 35px;
    font-weight: 500;
    padding-left: 10px;
  }

  .card-role {
    font-family: PPNeueMachina;
    /* text-transform: capitalize; */
    font-size: 30px;
    line-height: 25px;
    margin: 40px 0;
    font-weight: 400;
    padding-left: 10px;
  }

  .card-description {
    font-family: PPNeueMachina;
    /* text-transform: capitalize; */
    font-size: 20px;
    line-height: 25px;
    margin: 35px 0;
    font-weight: 300;
    padding-left: 10px;
  }

  
 