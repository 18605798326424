@import "../../vars.css";

.container {
    position: relative;
    background-color: rgba(12, 12, 12, 0.6);
    border-radius: 15px;
    padding: 73px;
    margin-top: 129px;
    z-index: 1;
    margin-left: 8%;
    margin-right: 8%;
}

.title {
    font-family: PPFragment;
    color: var(--color-text);
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
}

.description {
    margin-top: 3rem;
    font-size: 30px;
    margin-bottom: 50px;
    font-family: PPNeueMachina;
    color: var(--color-text);
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.aboutImg {
    width: 100%;
    height: 550px;
}

.aboutItems {
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.aboutItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    list-style: none;
    padding: 25px;
    background-image: linear-gradient(
      90deg,
      rgba(130, 130, 166, 0.42) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s;
  }

.aboutItem:hover {
    background-size: 100% 100%;
}

.aboutItemImg {
    width: 15%;
    padding-right: 8px;
}

.aboutItem h3 {
    font-size: 25px;
    font-weight: 600;
}

.aboutItem p {
    font-size: 25px;
}

@media screen and (max-width: 1207px) {
    .container {
        /* background-color: transparent; */
        /* padding-left: 0;
        padding-right: 0; */
        margin-left: 4%;
        margin-right: 4%;
        
    }

    .content {
        flex-direction: column;
    }

    .aboutImg {
        /* display: none; */
        width: 100%;
        height: 300px;
    }
    .aboutItems {
        margin-top: 29px;
    }
    .aboutItem {
        padding-left: 0;
        padding-right: 0;
    }
}