@import '../../vars.css';

.container {
    margin-top: 12rem;
    color: var(--color-text);
    margin-left: 8%;
    margin-right: 8%;
}

.title {
    font-family: PPFragment;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75;
    /* text-transform: uppercase; */
}

.projects {
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%
}