@import '../../vars.css';

.container {
    color: var(--color-text-secondary);
    margin-top: 120px;
    background-color: rgba(255, 244, 237, 0.91);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 58px 10%;
    gap: 10%;
}

.title {
    font-family: PPFragment;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0px;
}
.subtitle {
    padding-top: 25px;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 4px;
}
.address {
    padding-top: 25px;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2px;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    gap: 26px;
}
.link {
    display: flex;
    align-items: center;
    gap: 25px;
}
.link a {
    color: var(--color-text-secondary);
    text-decoration: none;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1.5px;
}

.link p {
    text-decoration: none;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1.5px;
}

.iconImg {
    width: 30px;
}

@media screen and (max-width: 830px) {
    .container {
      flex-direction: column;
      align-items: flex-start;
      list-style: none;
      gap: 23px;
    }
  
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .links {
      align-items: start;
    }
  }